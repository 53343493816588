/* eslint-disable no-nested-ternary */
import axios from 'axios';
// import { batch } from 'react-redux';

const env = process.env.REACT_APP_ENV;
const API_ROOT =
  env === 'development'
    ? 'http://localhost:8001/api'
    : env === 'staging'
    ? 'https://test.sharpener.tech/api'
    : 'https://production.sharpener.tech/api';
// : 'https://api.admin.sharpener.tech/api';

axios.defaults.baseURL = API_ROOT;
axios.defaults.timeout = 10 * 1000;
axios.defaults.headers = {};

const setToken = (token) => {
  axios.defaults.headers.common = { token };
};
const getToken = () => {
  return axios.defaults.headers.common;
};
const responseBody = (response) => response;

const requests = {
  delete: (url, data) => axios.delete(`${url}`, { data }).then(responseBody),
  get: (url, props) => axios.get(`${url}`, props).then(responseBody),
  // getAndPushToUrl: url => superagent.get(`${url}`).use(tokenPlugin).then(responseBody),
  getPaginated: (url, pageNum) =>
    axios.get(`${url}`).set('page_num', pageNum).then(responseBody),
  put: (url, body) => axios.put(`${url}`, body).then(responseBody),
  post: (url, body) => axios.post(`${url}`, body).then(responseBody),
  postFile: (url, data) => {
    const formData = new FormData();
    data.forEach((d) => {
      formData.append(d.key, d.file);
    });

    return axios
      .post(`${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(responseBody);
  },
};

const Auth = {
  login: (data, query) => requests.post(`/auth/login${query}`, data),
  register: (user) => requests.post('/auth/register', user),
  forgotPassword: (data) => requests.post('/auth/forgot-password', data),
  resetPassword: (password) => requests.post('/auth/reset-password', password),
};

const Batches = {
  createBatch: (batch) => requests.post('/mentor/batch/create', batch),
  deleteBatch: (batchId) => requests.post(`/mentor/batch/delete/${batchId}`),
  setAssignment: (assignmentData) =>
    requests.post('/mentor/batch/set-assignment', assignmentData),
  getAllBatchs: (query) =>
    requests.get(`/mentor/batch/get${query ? query : ''}`),
  updateBatch: (batchData, batchId) =>
    requests.post(`/mentor/batch/update/${batchId}`, batchData),
  getAssignmentsOfABatch: (batchID) =>
    requests.get(`/mentor/batch/assignments?batch=${batchID}`),
  setAssignmentToABatch: (assignmentData) =>
    requests.post('/mentor/batch/set-assignment', assignmentData),
  deleteAssignment: (assignmentData) =>
    requests.post('/mentor/batch/delete-assignment', assignmentData),
  assignISAtoBatch: (batch) => requests.post('/mentor/batch/isa/assign', batch),
  getIsaType: () => requests.get('/mentor/isa/get'),
  getTasksAssigned: (batchID) =>
    requests.get(`/mentor/batch/overview/assigned-tasks?batch=${batchID}`),
  getTasksSubmitted: (batchID) =>
    requests.get(`/mentor/batch/overview/submitted-tasks?batch=${batchID}`),
};

const User = {
  getUserDetails: (user) => requests.get(`/mentor/user/details?user=${user}`),
  getAllUsers: () => requests.get('/mentor/user/all'),
  getUsersOfABatch: (batchId) =>
    requests.get(`/mentor/user/batch/?batch=${batchId}`),
  assignBatch: (batchID) => requests.post('/mentor/user/assign-batch', batchID),
  rejectUser: (user) => requests.post('/mentor/user/reject', user),
  updateUserstatus: (user) => requests.post('/mentor/user/status/update', user),
  getFilteredUsers: (optionString) =>
    requests.get(`/mentor/user/get?${optionString}`),
  addPoints: (points) => requests.post('/mentor/user/points/add', points),
  updateBillingCycle: (cycle) =>
    requests.post('/mentor/user/billing-cycle/update', cycle),
  updateUserDetails: (details) => requests.post('/mentor/user/update', details),
  addFlag: (user) => requests.post('/mentor/user/flag/add', user),
  deleteFlag: (userId) => requests.delete(`/mentor/user/flag/delete/${userId}`),
  downloadUserReport: (query) =>
    requests.get(`/mentor/course/download/course-overview${query}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'text/csv',
      },
    }),
  getResume: (UserId) =>
    requests.get(`/admin/user/resume/get?userId=${UserId}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    }),
};

const Assignments = {
  reorderAssignments: (assignments) =>
    requests.post('/mentor/assignment/reorder', assignments),
  getAllAssignments: (query = '') =>
    requests.get(`/mentor/assignment/get${query}`),
  getAllAssignmentsWithPagination: (query = '') =>
    requests.get(`/mentor/assignment/get-with-pagination${query}`),
  createAssignment: (assignment) =>
    requests.post('/mentor/assignment/create', assignment),
  updateAssignment: (assignment, assignmentId) =>
    requests.post(`/mentor/assignment/update/${assignmentId}`, assignment),
  updateAssignmentIcon: (assignment, assignmentId) =>
    requests.postFile(
      `/mentor/assignment/update-icon/${assignmentId}`,
      assignment
    ),
  deleteAssignmentIcon: (assignmentId) =>
    requests.delete(`/mentor/assignment/delete-icon/${assignmentId}`),
  updateArchivedAssignments: (assignmentId) =>
    requests.post('/mentor/assignment/update-archived', assignmentId),
  deleteAssignment: (assignmentId) =>
    requests.post(`/mentor/assignment/delete/${assignmentId}`),
  getAllTasks: (query = '') => requests.get(`/mentor/task/get${query}`),
  createTask: (task) => requests.post('/mentor/task/add', task),
  createTastTestCase: (cases) =>
    requests.post('/mentor/task/test-case/add', cases),
  runTestCases: (cases) => requests.post('/mentor/task/test-case/run', cases),
  checkTestCaseStatus: (data) =>
    requests.post('/mentor/task/test-case/status', data),
  updateTask: (task, taskId) =>
    requests.post(`/mentor/task/update/${taskId}`, task),
  deleteTask: (taskId) => requests.post(`/mentor/task/delete/${taskId}`),
  createTaskHint: (taskHint) =>
    requests.post('/mentor/task/hint/add', taskHint),
  getTaskHint: (taskId) =>
    requests.get(`/mentor/task/hint/add?$taskId=${taskId}`),
  updateTaskHint: (taskHint) =>
    requests.post(`/mentor/task/hint/update`, taskHint),
  deleteTaskHint: (taskHintId) =>
    requests.post(`/mentor/task/hint/delete`, taskHintId),
  createReviewQuestion: (question) =>
    requests.post('/mentor/task/review-question/add', question),
  updateReviewQuestion: (question) =>
    requests.post(`/mentor/task/review-question/update`, question),
  deleteReviewQuestion: (questionId) =>
    requests.post(`/mentor/task/review-question/delete`, questionId),
  getAllAssignmentTaskTags: () => requests.get('/mentor/task/tags/get'),
  addAssignmentToACourse: (data) =>
    requests.post('/mentor/course/add-old-assignment', data),
  getUserTasks: (query) => requests.get(`/mentor/user/tasks${query}`),
  addCssTaskInputOutput: (data) =>
    requests.post(`/mentor/task/css/add-input-output`, data),
  getCssTaskInputOutput: (query) =>
    requests.get(`/mentor/task/css/get-input-output${query}`),

  addBoilerPlateCode: (code) =>
    requests.post(`/mentor/task/boilerplate/add`, code),
  updateBoilerPlateCode: (id, code) =>
    requests.post(`/mentor/task/boilerplate/update/${id}`, code),
  deleteBoilerPlateCode: (id, code) =>
    requests.post(`/mentor/task/boilerplate/delete/${id}`, code),

  addSolution: (data) => requests.post(`/mentor/task/add-solution`, data),

  createTaskQuestion: (data) =>
    requests.post(`/mentor/task/questions/create`, data),
  reorderTaskQuestion: (data) =>
    requests.post(`/mentor/task/questions/reorder`, data),
  updateTaskQuestion: (data) =>
    requests.post(`/mentor/task/questions/update`, data),
  getTaskQuestions: (query = '') =>
    requests.get(`/mentor/task/questions${query}`),
  deleteTaskQuestionAnswer: (data) =>
    requests.post(`/mentor/task/questions/answer/delete`, data),
  deleteTaskQuestion: (data) =>
    requests.post(`/mentor/task/questions/delete`, data),
  getUserAssignmentSections: (query) =>
    requests.get(`/mentor/assignment/section/user/sections?${query}`),
  undoAssignmentSection: (data) =>
    requests.post('/mentor/assignment/section/undo', data),

  addTestCase: (data) => requests.post(`/mentor/task/add/test-case`, data),
  getATask: (taskId) => requests.get(`/mentor/task/get/${taskId}`),
  updateTestCase: (data) =>
    requests.post(`/mentor/task/test-case/update`, data),
  deleteTestCase: (id, query) =>
    requests.delete(`/mentor/task/test-case/${id}?${query}`),
  uploadBoilerPlateCodePackage: (query = '', data) =>
    requests.postFile(`/mentor/task/code-package/upload${query}`, data),
  getCodePackage: (query) =>
    requests.get(`/mentor/task/code-package/get${query}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/x-zip-compressed',
      },
    }),
};

const TalkToMentor = {
  sendDetails: (data) => requests.post('/contact/', data),
};

const Category = {
  getCategories: () => requests.get('/mentor/categories/get'),
  addCategories: (category) =>
    requests.post('/mentor/categories/add', category),
  updateCategory: (category) =>
    requests.post('/mentor/categories/update', category),
};

const BatchAssignment = {
  getBatchAssignmentStatus: (batchId, assignmentId) =>
    requests.get(
      `/mentor/batch/assignment/status?batchId=${batchId}&assignmentId=${assignmentId}`
    ),
};
const AssignmentSubmissions = {
  getUserAssignments: (query = '') =>
    requests.get(`/mentor/user/assignments${query}`),
  getAnUserAssignment: (userId, assignmentId) =>
    requests.get(
      `/mentor/user/assignments?UserId=${userId}&AssignmentId=${assignmentId}`
    ),
  undoAssignmentSubmission: (data) =>
    requests.post('mentor/user/assignment/undo-assignment-submission', data),
  getTaskStatusOfAnAssignment: (userId, assignmentId) =>
    requests.get(
      `/mentor/user/task/status?assignmentId=${assignmentId}&userId=${userId}`
    ),
  reviewTask: (data) => requests.post('/mentor/user/task/review', data),
  undoReviewTask: (data) => requests.post('mentor/user/task/undo-review', data),
  undoAssignmentTaskSubmission: (data) =>
    requests.post('mentor/user/task/undo-task-submission', data),
  reviewAssignment: (data) =>
    requests.post('/mentor/user/assignment/review', data),
  undoReviewAssignment: (data) =>
    requests.post('mentor/user/assignment/undo-review', data),
  undoParticularAssignmentSubmission: (data) =>
    requests.post(
      'mentor/user/task/undo-task-submission-of-particular-assignment',
      data
    ),
  undoCourseSubmission: (data) =>
    requests.post('mentor/user/course/undo-submission', data),
};

const Enquiries = {
  getAllEnquiries: () => requests.get('/mentor/enquiry/get'),
  updateEnquiry: (enquiry, enquiryId) =>
    requests.post(`/mentor/enquiry/update/${enquiryId}`, enquiry),
  deleteEnquiry: (enquiryId) =>
    requests.delete(`/mentor/enquiry/delete/${enquiryId}`),
};
const Questions = {
  getQuestions: (query) => requests.get(`/mentor/question/all${query}`),
  deleteQuestion: (questionId) =>
    requests.delete(`/mentor/question/delete/${questionId}`),
  submitAnswer: (data) => requests.post(`/mentor/answer/add`, data),
  flagAnswer: (data) => requests.post(`/mentor/answer/flag`, data),
  markQuestionAsAnswerd: (data) =>
    requests.post(`/mentor/question/mark-as-answered`, data),
  deleteAnswer: (data) => requests.delete(`/mentor/answer/delete`, data),
};

const ErrorPage = {
  getBackendErrors: () => requests.get('/mentor/errors/get'),
  updateBackendError: (error) =>
    requests.post(`/mentor/errors/update/${error.id}`, error),
  deleteBackendError: (error) =>
    requests.delete(`/mentor/errors/delete/${error.id}`),

  getFrontendErrors: () => requests.get('/mentor/frontend-errors/get'),
  updateFrontendError: (error) =>
    requests.post(`/mentor/frontend-errors/update/${error.id}`, error),
  deleteFrontendError: (error) =>
    requests.delete(`/mentor/frontend-errors/delete/${error.id}`),
};

const UserFlags = {
  postComment: (comment) =>
    requests.post(`/mentor/user/flag/add-comment`, comment),
  getUserFlags: (query = '') => requests.get(`/mentor/user/flag/get${query}`),
};

const Courses = {
  getAllCourses: (query) =>
    requests.get(`/mentor/course/all${query ? query : ''}`),

  addOrUpdateCourseClass: (classTime) =>
    requests.post('/mentor/course/class/add-update', classTime),

  archiveCourseClass: (id, status) =>
    requests.post(`/admin/course/class/archive/${id}`, status),

  getAllCourseClass: (query) =>
    requests.get(`/admin/course/class/get${query ? query : ''}`),

  addCourse: (course) => requests.post(`/mentor/course/add`, course),

  getACourse: (id) => requests.get(`/mentor/course?courseId=${id}`),
  updateCourse: (id, course) =>
    requests.post(`/mentor/course/update/${id}`, course),
  deleteCourse: (id) => requests.delete(`/mentor/course/delete/${id}`),
  addAssignment: (data) => requests.post(`/mentor/course/add-assignment`, data),

  getStudentsOfaCourse: (query = '') =>
    requests.get(`/mentor/course/students${query}`),

  getCoursesOfStudents: () => requests.get(`/mentor/students/all-courses`),

  deleteCourseStudents: (id) =>
    requests.delete(`/mentor/course/student/delete/${id}`),
  addCourseStudent: (data) =>
    requests.post(`/mentor/course/student/assign`, data),

  addCoursePrerequisite: (data) =>
    requests.post(`/mentor/course/prerequisites/add`, data),
  deleteCoursePrerequisite: (data) =>
    requests.post(`/mentor/course/prerequisites/delete`, data),
  updateMentorClassJoinStatus: (data) =>
    requests.post(`/mentor/course/class/join-status/update`, data),
};

const Colleges = {
  getColleges: (query = '') => requests.get(`/colleges${query}`),
  addCollege: (college) => requests.post('/mentor/college/add', college),
  updateCollege: (id, college) =>
    requests.post(`/mentor/college/update/${id}`, college),
  deleteCollege: (id) => requests.delete(`/mentor/college/delete/${id}`),
};

const Messages = {
  sendMessage: (data) => requests.post('/mentor/message/add', data),
};

const Appointments = {
  getAllAppointments: (query = '') =>
    requests.get(`/mentor/appointment/owners/all${query}`),
  getAppointmentHistory: (query = '') =>
    requests.get(`/mentor/appointment/history${query}`),
  addMentorSlots: (data) =>
    requests.post(`/mentor/appointment/owners/slots/add`, data),
  getStudentsAppointment: (query = '') =>
    requests.get(`/mentor/appointment/student${query}`),
  changeStatus: (data) =>
    requests.post(`/mentor/appointment/add-feedback`, data),
  startAppointment: (data) => requests.post(`/mentor/appointment/start`, data),
  updateMeetingLinks: (data) =>
    requests.put(`/mentor/appointment/meeting-link/update`, data),
  updateMeetingRecordingURL: (data) =>
    requests.put(`/mentor/appointment/meeting/recording/update`, data),
  getQuestions: (query) =>
    requests.get(`/mentor/appointment/questions${query}`),
  submitQuestionFeedback: (data) =>
    requests.post('/mentor/appointment/question/feedback/submit', data),
};

const Strike = {
  getUserStrikes: (query) => requests.get(`/mentor/user/strike${query}`),
  addUserStrike: (data) => requests.post('/mentor/user/strike', data),
  deleteUserStrike: (query) => requests.delete(`/mentor/user/strike${query}`),
};

const Team = {
  getAllTeams: (query = '') =>
    requests.get(`/mentor/user/mentor-team/get/all/${query}`),
  updateTeam: (data) => requests.put(`/mentor/user/mentor-team/update`, data),
};

const feedback = {
  getFeedback: (query) => requests.get(`/mentor/feedback/mentor/${query}`),
  getMentorRatings: (query = '') =>
    requests.get(`/mentor/feedback/mentor/activity/${query}`),
};

console.log('added log');

export default {
  setToken,
  requests,
  getToken,
  TalkToMentor,
  Auth,
  Batches,
  User,
  Assignments,
  AssignmentSubmissions,
  BatchAssignment,
  Enquiries,
  ErrorPage,
  UserFlags,
  Courses,
  Questions,
  Colleges,
  Messages,
  Category,
  Appointments,
  Strike,
  Team,
  feedback,
};
